







import { Component, Vue } from "vue-property-decorator";
import AnnouncementTable from "@/components/Announcement/AnnouncementTable.vue";

@Component({
  components: {
    AnnouncementTable,
  },
})
export default class AnnouncementTableView extends Vue {}
