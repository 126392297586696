






















































































































































































































import { Component, PropSync, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/clients/Snackbar.module";
import { AnnouncementModel } from "@/types/Announcement";
import CompaniesModule from "@/store/clients/Companies.module";
import AnnouncementModule from "@/store/clients/Announcement.module";
import { Company } from "@/types/company";
import { AnnouncementRole } from "@/types/Announcement";
import AnnouncementClient from "Clients/Announcement-client";

const Snackbar = getModule(SnackbarModule, store);
const Companies = getModule(CompaniesModule, store);
const Announcement = getModule(AnnouncementModule, store);

@Component
export default class AddAnnouncement extends Vue {
  //Two way prop to handle open/close popup both from parent and component
  @PropSync("openPopup", { type: Boolean, default: false }) open!: boolean;

  title = "";
  announcementDetails = "";
  role: string | null = "";
  availableRoles: string[] = ["User", "SuperUser", "KognifaiUser", "Administrator"];
  isFormValid = true;
  submitting = false;
  companies: Company[] = [];
  selectedCompanies: Company[] = [];
  selectedRoles: string[] = [];
  isAnnouncementChecked = true;
  PublishDate = "";
  announcementModel: AnnouncementModel[] = [];
  listcompanyID: any[] = [];
  listRoleName: AnnouncementRole[] = [];
  isEmailChecked = true;
  publishDateText = false;

  announcementTitleValidation = {
    required: (value: string) => !!value || "Announcement title required",
  };
  announcementDetailsValidation = {
    required: (value: string) => !!value || "Announcement details required",
  };

  companySelectValidation = {
    required: (value: any) => value.length > 0 || "Companies required",
  };

  roleSelectValidation = {
    required: (value: any) => value.length > 0 || "Roles required",
  };
  publishdateValidation: any = {
    required: (value: string) => !!value || "Publish date and time required",
  };
  emailCheck: any = { required: true };

  companyModel = {
    allAvailableCompanies: this.companies,
    selectedCompanies: this.selectedCompanies,
  };

  roleModel = {
    allAvailableRoles: this.availableRoles,
    selectedRoles: this.selectedRoles,
  };

  async created(): Promise<void> {
    await this.fetchdata();
  }

  async fetchdata(): Promise<void> {
    const response = await Companies.getAll();
    this.companies = response;
    this.companyModel.allAvailableCompanies = response;
    this.roleModel = {
      allAvailableRoles: this.availableRoles,
      selectedRoles: this.selectedRoles,
    };
  }

  async createNewAnnouncement(): Promise<void> {
    if (this.selectedCompaniesLength === this.companyModel.allAvailableCompanies.length) {
      for (var i in this.companyModel.selectedCompanies) {
        this.listcompanyID.push({
          CompanyId: this.companyModel.selectedCompanies[i].id,
          CompanyName: this.companyModel.selectedCompanies[i].name,
          AnnouncementId: 1,
        });
      }
    } else {
      for (var i in this.companyModel.selectedCompanies) {
        this.listcompanyID.push({
          CompanyId: this.companyModel.selectedCompanies[i],
          CompanyName: "",
          AnnouncementId: 1,
        });
      }
    }

    for (var i in this.roleModel.selectedRoles) {
      this.listRoleName.push({ RoleId: this.roleModel.selectedRoles[i], RoleName: "" });
    }

    try {
      const command = {
        Id: 1,
        Title: this.title,
        AnnouncementDetails: this.announcementDetails,
        PublishDate: this.PublishDate,
        IsActive: true,
        Myselect: this.companyModel.selectedCompanies,
        AnnouncementCompany: this.listcompanyID,
        AnnouncementRole: this.listRoleName,
      };
      if (this.isAnnouncementChecked) {
        await Announcement.addNewAnnouncement(command);
        Snackbar.showSnackbar({
          text: "New Announcement <b>created</b>",
          color: "success",
        });
      }
      if (this.isEmailChecked) {
        const newEmail = await AnnouncementClient.sendEmail(command);
        if (newEmail.value == "Success") {
          Snackbar.showSnackbar({
            text: "Email sent <b>successfully</b>",
            color: "success",
          });
        } else {
          Snackbar.showSnackbar({
            text: newEmail.value,
            color: "success",
          });
        }
      }
      this.confirm();
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to create new announcement" });
    }
  }

  announcementCheckBoxEvent(): void {
    if (this.isAnnouncementChecked) {
      this.publishdateValidation = {
        required: (value: string) => !!value || "Publish date and time required",
      };
      this.emailCheck = { required: true };
      this.publishDateText = false;
    } else {
      this.publishdateValidation = { required: true };
      this.PublishDate = "";
      this.publishDateText = true;
      this.emailCheck = { required: (value: string) => !!value || "Email required" };
    }
  }

  confirm(): void {
    this.open = false;
    this.resetFieldData();
    this.$root.$emit("fetchData");
    this.$root.$emit("fetchAnnouncementData");
    this.$root.$emit("announcementlistByUser");
    this.fetchdata();
  }

  cancel(): void {
    this.resetFieldData();
    this.open = false;
    this.fetchdata();
  }

  closeModal(): void {
    this.open = false;
    this.resetFieldData();
  }

  get SelectAllCompanies(): boolean {
    return this.selectedCompaniesLength === this.companyModel.allAvailableCompanies.length;
  }

  get SelectSomeCompanies(): boolean {
    return this.selectedCompaniesLength > 0 && !this.SelectAllCompanies;
  }

  get icon(): string {
    if (this.SelectAllCompanies) return "mdi-close-box";
    if (this.SelectSomeCompanies) return "mdi-minus-box";
    return "mdi-checkbox-blank-outline";
  }

  toggle(): void {
    this.$nextTick(() => {
      if (this.SelectAllCompanies) {
        this.companyModel.selectedCompanies = [];
      } else {
        this.companyModel.selectedCompanies = this.companyModel.allAvailableCompanies.slice();
      }
    });
  }

  get SelectAllRoles(): boolean {
    return this.selectedRolesLength === this.roleModel.allAvailableRoles.length;
  }

  get SelectSomeRoles(): boolean {
    return this.selectedRolesLength > 0 && !this.SelectAllRoles;
  }

  get iconRole(): string {
    if (this.SelectAllRoles) return "mdi-close-box";
    if (this.SelectSomeRoles) return "mdi-minus-box";
    return "mdi-checkbox-blank-outline";
  }

  toggleRole(): void {
    this.$nextTick(() => {
      if (this.SelectAllRoles) {
        this.roleModel.selectedRoles = [];
      } else {
        this.roleModel.selectedRoles = this.roleModel.allAvailableRoles.slice();
      }
    });
  }

  resetFieldData(): void {
    this.selectedCompanies = [];
    this.isFormValid = true;
    this.selectedRoles = [];
    this.companyModel = { allAvailableCompanies: [], selectedCompanies: [] };
    this.roleModel = { allAvailableRoles: [], selectedRoles: [] };
    this.title = "";
    this.announcementDetails = "";
    this.PublishDate = "";
    this.companyModel.selectedCompanies = [];
    this.companyModel.allAvailableCompanies = [];
    this.listcompanyID = [];
    this.listRoleName = [];
  }

  get selectedCompaniesLength(): number {
    return this.companyModel.selectedCompanies.length;
  }
  get selectedRolesLength(): number {
    return this.roleModel.selectedRoles.length;
  }
}
