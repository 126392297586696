




























































































































import { Component, PropSync, Vue } from "vue-property-decorator";
import Utilities from "@/utilities/utilities";
import { FormattedError } from "@/types/formattedError";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/clients/User.module";

const User = getModule(UserModule, store);

@Component
export default class EditProfile extends Vue {
  //Two way prop to handle open/close popup both from parent and component
  @PropSync("openPopup", { type: Boolean, default: false }) open!: boolean;

  name = "";
  email = "";
  title = "";
  phoneNumber: string | null = "";
  role: string | null = "";
  isFormValid = true;
  submittedError = false;
  submitting = false;
  errorResponse!: FormattedError;
  showUpdatedProfileNotification = false;
  notificationDurationMs = 3500;
  canEditRoles = User.isAdmin;
  availableRoles = ["User", "SuperUser", "KognifaiUser", "Administrator"];

  emailValidation = {
    required: (value: string) => !!value || "Email required",
    valid: (value: string) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || "Invalid email",
  };
  nameValidation = {
    required: (value: string) => !!value || "Name required",
  };
  titleValidation = {
    required: (value: string) => !!value || "Title required",
  };
  phoneNumberValidation = {
    valid: (value: string) => {
      if (value) return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[ 0-9]*$/.test(value) || "Invalid phone number. Use only +, () and digits.";
      else return true;
    },
  };

  roleValidation = {
    required: (value: string) => !!value || "Role required",
  };

  created(): void {
    this.setUserDetails();
  }

  closeModal(): void {
    this.open = false;
    this.setUserDetails();
  }

  setUserDetails(): void {
    this.name = User.userName;
    this.email = User.userEmail;
    this.title = User.userTitle;
    this.phoneNumber = User.userPhone;
    this.role = User.userRole;
  }

  handleUpdateProfile(): void {
    this.submittedError = false;
    this.submitting = true;
    User.updateUser({
      id: User.userId,
      name: this.name,
      title: this.title,
      phoneNumber: this.phoneNumber,
      role: this.role,
    }).then(
      () => {
        this.submitting = false;
        this.open = false;
        this.showProfileUpdatedNotification();
      },
      error => {
        this.errorResponse = Utilities.formatError(error);
        this.submittedError = true;
        this.submitting = false;
      }
    );
  }

  showProfileUpdatedNotification(): void {
    this.showUpdatedProfileNotification = true;
  }
}
