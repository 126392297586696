
























































import { Component, Vue, Ref } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import EditProfile from "@/components/EditProfile.vue";
import AddAnnouncement from "@/components/Announcement/AddAnnouncement.vue";
import SnackbarModule from "@/store/clients/Snackbar.module";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import dateHelper from "Utilities/date-helper";
import AnnouncementModule from "@/store/clients/Announcement.module";
import { Announcement } from "@/types/Announcement";

const Snackbar = getModule(SnackbarModule, store);
const Announcement = getModule(AnnouncementModule, store);

@Component({
  components: {
    EditProfile,
    AddAnnouncement,
    ConfirmDialog,
  },
})
export default class AnnouncementTable extends Vue {
  @Ref("confirmDelete") confirmDelete!: any;
  AddAnnouncementPopup = false;

  headers = [
    {
      text: "Announcement Title",
      value: "title",
      class: "v-data-table-column-header",
      width: "30%",
    },
    {
      text: "Announcement Details",
      value: "announcementDetails",
      class: "v-data-table-column-header",
      width: "40%",
    },
    {
      text: "Publish Date(UTC)",
      value: "publishDate",
      class: "v-data-table-column-header",
      width: "15%",
    },
    {
      text: "Created Date(UTC)",
      value: "createdDate",
      class: "v-data-table-column-header",
      width: "15%",
    },
    {
      text: "Created By",
      value: "createdBy",
      class: "v-data-table-column-header",
      width: "15%",
    },
    {
      text: "Delete",
      value: "actions",
      class: "v-data-table-column-header",
      width: "10%",
      sortable: false,
    },
  ];

  columns: string[] = [];
  announcements: Announcement[] = [];

  mounted(): void {
    this.$root.$on("fetchData", async () => {
      await this.fetchData();
    });
  }

  async created(): Promise<void> {
    await this.fetchData();
  }

  async fetchData(): Promise<void> {
    const response = await Announcement.fetchAllAnnouncement();
    this.announcements = response;
  }

  async deleteEvent(selectedRow: any): Promise<void> {
    const confirmed = await this.confirmDelete.open("Delete", "Are you sure you want to delete this Announcement?");
    if (confirmed) {
      await Announcement.deleteAnnouncement(selectedRow.id);
      Snackbar.showSnackbar({
        text: "Announcement <b>deleted</b>",
        color: "success",
      });
      this.fetchData();
      this.$root.$emit("fetchAnnouncementData");
      this.$root.$emit("announcementlistByUser");
    }
  }

  formatDateTime(date: string): string {
    return dateHelper.getFormatedDateTimeString(date);
  }
}
