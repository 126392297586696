var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"user__actions"},[_c('v-btn',{staticClass:"mr-2 mb-2 font-weight-semibold",attrs:{"color":"accent"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.AddAnnouncementPopup = true}}},[_vm._v(" Add Announcement ")])],1),_c('div',{staticClass:"user__actions"},[_c('v-data-table',{staticClass:"users-table elevation-1",staticStyle:{"line-height":"250%"},attrs:{"headers":_vm.headers,"items":_vm.announcements,"dense":"","loading-text":"Loading... Please wait","no-data-text":"No announcements","hide-default-footer":"","disable-pagination":"","fixed-header":"","sort-by":"createdDate","sort-desc":true},scopedSlots:_vm._u([{key:"item.publishDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.publishDate)))])]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.createdDate)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.deleteEvent(item)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Announcement")])])]}},{key:"no-data",fn:function(){return [_vm._v(" NO ANNOUNCEMENTS ")]},proxy:true}],null,true)})],1),_c('AddAnnouncement',{attrs:{"openPopup":_vm.AddAnnouncementPopup},on:{"update:openPopup":function($event){_vm.AddAnnouncementPopup=$event},"update:open-popup":function($event){_vm.AddAnnouncementPopup=$event}}}),_c('ConfirmDialog',{ref:"confirmDelete"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }